<template>
    <div class="con-select-video">
        <a-input v-model:value="val">
            <template #addonAfter>
                <div class="csv-btn" @click="showVideoList">选择视频</div> 
            </template>
        </a-input>
        <a-modal v-model:visible="show" :footer="null" @cancel="show = false" width="900px">
            <template #title>
				<span>选择视频</span>
				<span style="margin: 0 12px;" class="f12" v-if="size">存储空间：{{ size.use_size }}/{{ size.max_size }}</span>
                <com-upload-btn :upload-type="2" @success="getVideoList(1,limit)"></com-upload-btn>
			</template>
            <div class="kvl-ul">
                <template  v-for="(item,index) in list">
                    <div class="kvlu-item" :key="index" v-if="item.type==2" @click="selectVideo(item.url)">
                        <div class="kvlu-item-icon"><i class="ri-play-fill"></i></div>
                        <div class="kvlu-item-time">创建时间：{{item.create_time}}</div>
                    </div>
                </template>
				<div style="width: 200px;height: 1px;"></div>
				<div style="width: 200px;height: 1px;"></div>
			</div>
            <div class="pager">
				<a-pagination show-size-changer
					:default-current="page"
					:total="count"
					@showSizeChange="(p,e)=>{getVideoList(page,e)}"
					@change="(e)=>{getVideoList(e,limit)}"
				/>
			</div>
        </a-modal>
    </div>
</template>
<script>
import { getCurrentInstance, reactive, toRefs, watch } from 'vue'
import comUploadBtn from '@/components/public/com-upload-btn.vue'
import commonModel from '@/api/common'
export default {
    name:'com-select-video',
    components:{
        comUploadBtn
    },
    props:{
        modelValue:{
            type:String,
            default:''
        }
    },
    setup(props,context) {
        const t = getCurrentInstance().proxy
        const data = reactive({
            val:props.modelValue,
            show:false,
            list:[],
            page:1,
            limit:8,
            count:0,
            size:{},
        })

        //监听当前绑定的值的数据变化 主要用户初始化监听v-model传入的值
        watch(() => props.modelValue,(val) => {
                data.val = val
            }
        )

        function showVideoList(){
            data.show = true
            getVideoList(1,data.limit)
        }
        //获取视频信息
        function getVideoList(page,limit){
            commonModel.getImageList(page,limit,{type:2},res=>{
                data.list = res.list
                data.page = res.page
                data.count = res.count
                data.size = res.size
            })
        }
        //确认选择视频
        function selectVideo(url){
            data.val = url
            data.show = false
            context.emit('update:modelValue',data.val)
        }
        return{
            ...toRefs(data),
            showVideoList,
            getVideoList,
            selectVideo,
        }
    },
}
</script>
<style lang="scss">
.csv-btn{
    cursor: pointer;
}
.kvl-ul{
    width: 100%;
    height: 350px;
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0;
    justify-content: space-between;
    border-top: 1px solid #f4f4f4;
    padding-top: 10px;
    .kvlu-item{
        width: 200px;
        height: 150px;
        border: 1px solid #e6e4e4;
        margin: 0 10px 10px 0;
        cursor: pointer;
        text-align: center;
        box-sizing: border-box;
        
        &-icon{
            width: 70px;
            height: 70px;
            margin:30px 65px;
            background: #d4d4d4;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 60px;
        }
        
        &-time{
            width: 199px;
            height: 30px;
            line-height: 30px;
            background: rgba(0,0,0,0.5);
            color: #fff;
            font-size: 12px;
            position: absolute;
            margin-top: -12px;
        }
    }
    .kvlu-item:hover{
        box-sizing: border-box;
        border: 1px solid #1A97EE;
    }
    }
</style>